import { graphql } from 'gatsby'
import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import SEO from "../components/seo"
import Amplify, { Storage, API, graphqlOperation } from "aws-amplify"
import "./index.scss"
import Background1 from "../images/backgrounds/1.png"
import Background2 from "../images/backgrounds/2.png"
import Background3 from "../images/backgrounds/3.png"
import Background4 from "../images/backgrounds/4.png"
import Background5 from "../images/backgrounds/5.png"
import Background6 from "../images/backgrounds/6.png"

const Hero = props => (
  <Link className="hero" to={props.link}>
    <div
      className="hero-center"
      style={{ background: `url(${props.background})` }}
    >
      <div>
        <div className="hero-header">{props.header}</div>
        <div className="hero-text">{props.text}</div>
      </div>
    </div>
  </Link>
)

const ImageViewer = props => {
  const [image, setImage] = useState(null)
  useEffect(() => {
    fetchImage()
  }, [])
  async function fetchImage() {
    try {
      const image = await Storage.get("thumbnail" + props.image)
      setImage(image)
    } catch {
      const image = await Storage.get(props.image)
      setImage(image)
    }
  }
  return image && <img width="70" height="70" src={image}></img>
}

const IndexPage = props => {
  useEffect(() => {
    var markers = []
    var markerPositions = []
    props.data.project.listProjects.items.map(project => {
      if (project.location !== null) {
        markers.push({
          position: [project.location.lat, project.location.lon],
          text: project.title,
          id: project.id,
          onHubs: project.onHubs,
          featured: project.featured
        })
        markerPositions.push([project.location.lat, project.location.lon])
      }
    })
    props.addMarker(markers)
    // props.flyToBounds(markerPositions)
    props.flyToZoom(3)

    props.flyTo([48.309736, 14.284343])

    return () => {
      // props.flyToBounds([])
      props.addMarker([])
    }
  }, [])

  return (
    <section className="homepage">
      <SEO title="Home" />
      <div className="padded">
        <h1>Mozilla Hubs Navigation Interface</h1>
        <p className="intro">
          This is the Ars Electronica navigation interface for Kepler's Gardens.
        </p>
        <div className="heroes">
          <Hero
            header="Lobby"
            text="Start here"
            background={Background1}
            link="/project/d2a3e521-5d03-42fd-b0fb-dd5329d7b0ef"
          />
          <Hero
            header="Browse"
            text="All projects"
            background={Background2}
            link="/browse"
          />
          <Hero
            header="Social Space"
            text="Meet at the Bar"
            background={Background3}
            link="/project/1ecd641c-2821-4b87-9be5-55e581b45379"
          />
          <Hero
            header="TV"
            text="Learn more"
            background={Background4}
            link="/tv-stations"
          />
          <Hero
            header="Ars-VR"
            text="Ars Electronica Gardens"
            background={Background5}
            link="/ars-vr"
          />
          <Hero
            header="VR Gardens"
            text="All VR experiences"
            background={Background6}
            link="/vr-experiences"
          />
        </div>
        <p>
          Browse by categories or choose projects on the world map to enter a
          virtual experience. The desktop/smartphone icons indicate what kind of
          device is required.
        </p>
        <p>
          Most rooms are optimized for desktop computers and will not perform
          adequately on smartphones.
        </p>
        <p>For general instructions and onboarding start in the lobby.</p>
      </div>
      <div className="featured">
        <div className="padded">
          <h2>Featured Projects</h2>
          <p>Select projects featured by our staff</p>
        </div>
        {props.data.project.listProjects.items.map(project => (
          <div key={project.id} className="project">
            <Link to={"/project/" + project.id}>
              <ImageViewer image={project.image} />
              <div className="title-and-description">
                <div className="title">{project.title}</div>
                <div className="subtitle">{project.subtitle}</div>
                <div className="description">
                  {project.description.substring(0, 55) + "..."}
                </div>
              </div>
            </Link>
          </div>
        ))}
      </div>
      {/* <h2>
        <Link to="/beammeup">Beam me up!</Link>
      </h2>
      <h2>
        <Link to="/page2">Page 2</Link>
      </h2> */}
      {/* <div className="padded"></div>
      <Link to="/partner/submit">Submit a project</Link> <br /> */}
    </section>
  )
}
export const query = graphql`
  {
    project {
      listProjects(
        filter: { approved: { eq: true }, featured: { eq: true } }
        limit: 10000
      ) {
        items {
          id
          title
          onHubs
          featured
          subtitle
          description
          image
          location {
            lat
            lon
          }
        }
      }
    }
  }
`

export default IndexPage
